import React from 'react';
import Seo from '../components/Seo';

const CustomPage = () => {
    return (
        <>
            <Seo
                title="Custom Page"
                description="Custom page description, remove this property to use default text"
            />
            <section style={{ background: 'blue', paddingTop: '100px', color: 'white' }}>
                <div className="container">
                    <p>hello world</p>
                </div>
            </section>
        </>
    );
};

export default CustomPage;
